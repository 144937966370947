import { AxiosError, AxiosResponse } from 'axios';

import { httpClient } from '@/api';
import { fetchConfig } from '@/init/app-config';
import { handleError, handleSuccess } from '@/services/helpers';
import store from '@/store/store';
import { CreditTransactionDefinition } from '@/types/CreditTransactions';
import { ResourcesDataDocument } from '@/types/JsonSpec';
import { PasswordResetRequest } from '@/types/PasswordResetRequest';
import { BetaContact, BetaContactDocument } from '@/types/resources/BetaContact';
import { OwnedVehicleDocument, OwnedVehiclesDocument } from '@/types/resources/OwnedVehicles';
import {
  PartialServiceRequestsDocument,
  ServiceRequestDocument,
  ServiceRequestsDocument
} from '@/types/ServiceRequest';
import { StandaloneAppointmentDocument } from '@/types/StandaloneAppointment';
import { EmployeeDocument, User, UserDocument, UserProfileAPI, UserTypeInfo } from '@/types/User';
import { UserReviewDocument, UserReviewsDocument } from '@/types/UserReview';
// Types
import { VehicleRequest } from '@/types/Vehicle';

export interface UserReviewCreationParams {
  rating: number;
  review: string;
  locationId: number;
  serviceRequestId: number;
}

const userId = () => store.getters['user/getUserProfile'].id;

export default {
  retrieveUserProfile(): Promise<User> {
    const config = fetchConfig();
    return httpClient
      .get<UserDocument | EmployeeDocument>(`v3/users/profile`)
      .then((response) => {
        // [MPC-5418] don't redirect SPs away from the trust and saftey policy
        const skipRedirect = window.location.href.includes('/trust-and-saftey');
        if (response.data.data.type === 'employee' && !skipRedirect) {
          window.location.replace(config.get('spDashboard'));
          throw 'redirecting';
        } else {
          return handleSuccess(response);
        }
      })
      .catch((error: AxiosError | string) => {
        if (typeof error !== 'string') throw handleError(error);
        else throw error;
      });
  },
  retrieveSavedCards(): Promise<any> {
    return httpClient
      .get(`v3/users/credit_cards`)
      .then((response: AxiosResponse<any>) => {
        return handleSuccess(response);
      })
      .catch((error: AxiosError) => {
        throw handleError(error);
      });
  },
  retrieveCreditTransactions() {
    return httpClient
      .get<ResourcesDataDocument<CreditTransactionDefinition>>(`v3/users/credit_transactions`)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  addPromoCode(promoCode: string): Promise<any> {
    return httpClient
      .post(`v3/users/promo`, { promoCode: promoCode })
      .then((response: AxiosResponse) => {
        return response.data;
      })
      .catch((error: AxiosError) => {
        throw handleError(error);
      });
  },

  fetchServiceRequest(id: number | string) {
    return httpClient
      .get<ServiceRequestDocument>(`v3/users/service_requests/${id}`)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  fetchServiceRequestByNpsToken(npsToken: string) {
    return httpClient
      .get<ServiceRequestDocument>(`v3/service_requests/${npsToken}`)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  fetchServiceRequests(
    scope: 'settled' | 'withdrawn' | 'expired' | 'pending' | 'accepted' | '' = '',
    page: number = 1,
    perPage: number = 5,
    all: boolean = false
  ) {
    return httpClient
      .get<ServiceRequestsDocument | PartialServiceRequestsDocument>(`v3/users/${userId()}/service_requests/${scope}`, {
        params: { page: page, per_page: perPage, all: all }
      })
      .then((response) => {
        const serviceRequestResponse = {
          serviceRequests: handleSuccess(response),
          count: response.data.meta!.count
        };
        return serviceRequestResponse;
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  fetchAppointment(appointmentId: string) {
    return httpClient
      .get<StandaloneAppointmentDocument>(`v3/users/${userId()}/standalone_appointments/${appointmentId}`)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  fetchAppointments(page: number = 1, perPage: number = 5, all: boolean = false) {
    return httpClient
      .get<StandaloneAppointmentDocument>(`v3/users/${userId()}/standalone_appointments`, {
        params: { page: page, per_page: perPage, all: all }
      })
      .then((response) => {
        const apptResponse = {
          appointments: handleSuccess(response),
          count: response.data.meta!.count
        };
        return apptResponse;
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  fetchVehicles() {
    return httpClient
      .get<OwnedVehiclesDocument>('v3/users/vehicles')
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  addOwnedVehicle(vehicle: VehicleRequest) {
    return httpClient
      .post<OwnedVehicleDocument>('v3/owned_vehicles', vehicle)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error: AxiosError) => {
        throw handleError(error);
      });
  },

  updateUserProfile(accountInfo: UserProfileAPI) {
    return httpClient
      .put<UserDocument>('v3/users/profile', accountInfo)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((err) => {
        let error = err.response.data.error;
        error =
          error === 'Email has already been taken and Email is in use already' ? 'Email is in use already.' : error;

        throw error;
      });
  },
  updatePassword(payload: any): Promise<any> {
    return httpClient
      .post('/v3/users/password', { ...payload })
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },
  updateBetaContacts(payload: BetaContact) {
    return httpClient
      .post<BetaContactDocument>('/v3/users/beta_contacts', payload)
      .then((response) => handleSuccess(response))
      .catch((error) => handleError(error));
  },
  createReviewForLocation(payload: UserReviewCreationParams) {
    return httpClient
      .post<UserReviewDocument>(`/v3/users/reviews/${payload.locationId}`, payload)
      .then((response) => handleSuccess(response))
      .catch((error) => handleError(error));
  },
  fetchReviews() {
    return httpClient
      .get<UserReviewsDocument>('/v3/users/reviews')
      .then((response) => handleSuccess(response))
      .catch((error) => handleError(error));
  },
  retrieveEmailType(email: string) {
    return httpClient
      .get<UserTypeInfo>(`/v3/users/type?email=${encodeURIComponent(email)}`)
      .then((response) => {
        return response.data;
      })
      .catch((error: AxiosError | string) => {
        if (typeof error !== 'string') throw handleError(error);
        else throw error;
      });
  },
  verifyEmail(email: string, landingPage: string) {
    return httpClient
      .put('/v3/users/email_verification', { email, landing_page: landingPage })
      .then((response) => {
        return response;
      })
      .catch((error: AxiosError | string) => {
        throw error;
      });
  },
  verifyPasswordResetToken(token: string) {
    return httpClient
      .post('/v3/users/password_reset/token', { password_reset_token: token })
      .then((response) => {
        return response;
      })
      .catch((error: AxiosError | string) => {
        throw error;
      });
  },
  resetPassword(payload: PasswordResetRequest): Promise<any> {
    return httpClient
      .post('/v3/users/password_reset', { ...payload })
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },
  submitNps(payload: Record<string, any>): Promise<any> {
    return httpClient
      .post('/v3/users/nps', { ...payload })
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },
  submitConfirmationCode(payload: { confirmationCode: string }): Promise<{ message: string }> {
    return httpClient
      .put('/v3/users/confirm_email', { ...payload })
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },
  resendConfirmationCode(): Promise<{ message: string }> {
    return httpClient
      .post('/v3/users/resend_confirmation_code')
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  }
};
