import Vue from 'vue';

import Router, { NavigationGuard, RawLocation, Route } from 'vue-router';
import { ErrorHandler } from 'vue-router/types/router';

import appointmentRoutes from '@/routes/appointment';
import autoRepairRoutes from '@/routes/autoRepair';
import maintenanceRoutes from '@/routes/maintenance';
import offerRoutes from '@/routes/offer';
import onrampRoutes from '@/routes/onramp';
import profileRoutes from '@/routes/profile';
import repairCostRoutes from '@/routes/repairCost';
import subscriptionRoutes, { subscriptionCreatePasswordRouteName } from '@/routes/subscription';
import store from '@/store/store';
import { Subscription } from '@/types/microservices/Subscriptions';
import { User } from '@/types/User';

import userService from './services/userService';

// Prevents errors like:
//
// - NavigationDuplicated: Avoided redundant navigation to current location: "/foo/bar"
// - Error: Redirected when going from "/foo/bar" to "/baz/bam" via a navigation guard.
// - Error: Navigation cancelled from "/foo" to "/bar" with a new navigation.
//
// See: https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
//
// Because: Navigation errors are incredibly noisy and are sent to Sentry, which
// we pay for, and the uncaught errors just about _never_ need handling; they
// are frequent and mundane events that we rarely care about.
const oldRouterPush = Router.prototype.push;
Router.prototype.push = function push(
  this: Router,
  location: RawLocation,
  onComplete?: Function,
  onAbort?: ErrorHandler
) {
  // TS thinks that doing `oldRouterPush.call(this, location)` returns `void`,
  // but the overload clearly indicates that it returns `Promise<Route>`. There
  // is some bug in the compiler, clearly, because if you simply `bind` it to
  // `this` first, then it works fine. Binding and then invoking the method is
  // effectively the same as using `call`, so there shouldn't be a difference...
  // :man_shrugging: Could just coerce it to the correct type, but that runs the
  // risk of silently becoming _incorrect_ as we update `vue-router`, whereas
  // this approach will kick and scream when it is no longer correct, so that we
  // can fix it promptly.
  const tsAwareOldPush = oldRouterPush.bind(this);
  if (onComplete || onAbort) return tsAwareOldPush(location, onComplete, onAbort);

  return tsAwareOldPush(location).catch((error) => {
    // If it's a navigation failure, swallow it. Otherwise, bubble.
    if (!Router.isNavigationFailure(error)) return Promise.reject(error);
  });
} as Router['push'];

Vue.use(Router);

declare module 'vue/types/vue' {
  interface Vue {
    $router: Router;
  }
}

const waitForStorageToBeReady: NavigationGuard = async (_to, _from, next) => {
  await (store as any).restored;
  next();
};

const storeGivenVin: NavigationGuard = (to, from, next) => {
  const vinParam = from.query.vin ?? to.query.vin;
  const vin = Array.isArray(vinParam) ? vinParam[0] : vinParam;
  if (vin) store.commit('onrampCart/setPreselectedVin', vin);
  next();
};

const isLoggedIn = (): boolean => {
  return store.getters['user/isLoggedIn'];
};

const hasPaywall = (): boolean => store.getters['user/mustPurchaseSubscription'];

const getPaywall = (): { programName: string; planName: string } => store.getters['user/paywalledProgramAndPlan'];

const accountIsConfirmed = (): boolean => {
  return store.getters['user/isConfirmed'];
};

const hereditaryMeta = <R>(route: Route, metaProperty: string): R | undefined => {
  // If the route has the given meta property defined, return its value.
  const routeMetaValue = route.meta?.[metaProperty];
  if (typeof routeMetaValue !== 'undefined') return routeMetaValue;

  // If the route does NOT have the given meta property defined (or it is set to
  // `undefined`), go through its ancestors in reverse (child to parent) and
  // return the first one that has a non-`undefined` value for the given meta
  // property; i.e., the given route will inherit the value of the given meta
  // property from its most immediate ancestor that has it set to a non-
  // `undefined` value.
  for (let i = route.matched.length - 1; i >= 0; i--) {
    const parent = route.matched[i];
    const parentMetaValue = parent.meta?.[metaProperty];
    if (typeof parentMetaValue !== 'undefined') return parentMetaValue;
  }
};

const routeIsPublic = (route: Route): boolean => {
  // If the route is marked as `public: true` or `public: false` in its `meta`
  // bag, we will return that value. If not, the `meta.public` value of its most
  // immediate ancestor that DOES have it set will be used. If no `public` value
  // is set in the ancestor chain, the route will be assumed to be NOT public,
  // and `false` will be returned.
  return hereditaryMeta<boolean>(route, 'public') ?? false;
};

const routeIsOnramp = (route: Route): boolean => {
  return hereditaryMeta<boolean>(route, 'onramp') ?? false;
};

const requireLoginForNonPublicRoutes: NavigationGuard = (to, from, next) => {
  // If it's a public route, let them through.
  if (routeIsPublic(to)) return next();
  const destination = store.getters['auth/getDestinationAfterAuth'];

  // If that's already where they're going, let them through.
  if (to.fullPath === destination) return next();

  // If they're logged in...
  if (isLoggedIn()) {
    // ...and they DON'T have a destination, let them through.
    if (!destination) return next();

    // ...and they DO have a destination, then clear it and go there instead.
    store.commit('auth/setDestinationAfterAuth', '');
    return next(destination);
  }
  // In case we want to lock everything down instead of just onramp
  // else if (!accountIsConfirmed()) {
  //   return next('/activate-account');
  // }

  // If they aren't logged in, then set the destination after they *do* log in
  // to the intended next URL here (unless they already have one; in which case,
  // keep the existing one)...
  if (!destination) store.commit('auth/setDestinationAfterAuth', to.fullPath);

  // ...and redirect them to sign in.
  next('/sign-in');
};

const userHasOffers = () => {
  const partnerOffers = store.getters['user/getPartnerOffers'];
  return partnerOffers.length > 0;
};

const requireSubscriberPasswordForNonPublicRoutes: NavigationGuard = (to, from, next) => {
  // Hey, that's where we want them to go! Nice.
  if (to.name === subscriptionCreatePasswordRouteName) return next();

  // If it's a public route (except the onramp routes), let them through.
  if (routeIsPublic(to) && !routeIsOnramp(to)) return next();

  // This check is only relevant for subscribers who have technically logged in
  // already, likely through an auto-sign in link from their partner in order to
  // create a password. So, if they're not logged in, let them go on to the next
  // navigation guard, or to their destination route if allowed.
  if (!isLoggedIn()) return next();

  // Users with passwords already set can do whatever the hell they want.
  const userProfile: User = store.getters['user/getUserProfile'];
  if (userProfile.hasPassword) return next();

  // User has no subscription... why don't they have a password? Doesn't matter,
  // we don't have a way of dealing with this at the time of writing.
  const subscriptions: Subscription[] = store.getters['user/getSubscriptions'] || [];
  if (subscriptions.length === 0) return next();

  // User has a subscription already and hasn't set a password yet. That means
  // they were created automatically by the subscription partner. They're logged
  // in, technically, so they would normally be able to move around the site...
  // but we really want them to create a password. Let's gently direct them to
  // the place where they can create a password. Gently, but _forcefully._
  const activeSubscription = subscriptions[0];
  const programName = activeSubscription.program.name;
  const planName = activeSubscription.program.plan.name;
  next({ name: subscriptionCreatePasswordRouteName, params: { programName, planName } });
};

const checkPaywallForLoggedInUsers: NavigationGuard = (to, from, next) => {
  // Hey, that's where we want them to go! Nice.
  if (['subscription-choose-plan', 'subscription-success', 'subscription-activate-account'].includes(to.name!))
    return next();

  if ((routeIsPublic(to) && !routeIsOnramp(to)) || !isLoggedIn() || !hasPaywall()) return next();

  const { programName, planName } = getPaywall();

  // sanity check
  if (!programName) return next();

  // if they're a paywalled user who hasn't confirmed their account yet,
  // make them do that first
  if (!accountIsConfirmed()) return next({ name: 'subscription-activate-account', params: { programName, planName } });

  next({ name: 'subscription-choose-plan', params: { programName, planName } });
};

const verifyPasswordResetToken: NavigationGuard = (to, from, next) => {
  userService
    .verifyPasswordResetToken(to.params['token'])
    .then(() => next())
    .catch(() => next('/sign-in'));
};

/*

For best performance, all components should be lazy loaded into their routes.
If a route loads a set of of component, they should be included in the same chunk
with the webpackChunkName syntax as seen below.

If your component/view is meant to be crawled by SEO bots, the component should
not be lazy loaded. Examples are the homepage, repair cost, and promo pages.
All SPA-specific components and views should be loaded asyncronously.

*/

/* type RouterMetaOptions = {
  disableTracking?: boolean;
  transitionName?: string;
  fluidContainer?: boolean;
  hideNavLinks?: boolean;
  showCloseButtonInNav?: boolean;
  whiteBackground?: boolean;
  hideMainSlot?: boolean;
}; */

const router = new Router({
  mode: 'history',
  base: '/',
  scrollBehavior(to, _from, _savedPosition) {
    if (to.meta?.preventScrollJump) return;
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "homepage" */ '@/views/HomepageV2.vue'),
      meta: {
        fluidContainer: true,
        public: true
      },
      beforeEnter: (_to, _from, next) => {
        if (isLoggedIn()) {
          next('/dashboard');
        } else {
          next();
        }
      }
    },
    {
      path: '/automotive-service-professionals',
      name: 'automotive-service-professionals',
      component: () =>
        import(/* webpackChunkName: "automotive-service-professionals" */ '@/views/AutomotiveServiceProfessionals.vue'),
      meta: {
        fluidContainer: true,
        public: true
      }
    },
    {
      path: '/trust-and-saftey',
      name: 'trust-and-saftey',
      component: () => import(/* webpackChunkName: "trust-and-saftey" */ '@/views/public/TrustAndSaftey.vue'),
      meta: {
        fluidContainer: true,
        public: true
      }
    },
    {
      path: '/tos',
      name: 'tos',
      component: () => import(/* webpackChunkName: "tos" */ '@/views/public/TermsOfService.vue'),
      meta: {
        fluidContainer: true,
        public: true
      }
    },
    {
      path: '/copyright',
      name: 'copyright',
      component: () => import(/* webpackChunkName: "copyright" */ '@/views/public/Copyright.vue'),
      meta: {
        fluidContainer: true,
        public: true
      }
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: () => import(/* webpackChunkName: "privacy-policy" */ '@/views/public/PrivacyPolicy.vue'),
      meta: {
        fluidContainer: true,
        public: true
      }
    },
    {
      path: '/openbay-faq',
      name: 'openbay-faq',
      component: () => import(/* webpackChunkName: "openbay-faq" */ '@/views/public/OpenbayFaq.vue'),
      meta: {
        fluidContainer: true,
        public: true
      }
    },
    {
      path: '/asp-faq',
      name: 'asp-faq',
      component: () => import(/* webpackChunkName: "asp-faq" */ '@/views/public/SpFaq.vue'),
      meta: {
        fluidContainer: true,
        public: true
      }
    },
    {
      path: '/peace-of-mind',
      name: 'peace-of-mind',
      component: () => import(/* webpackChunkName: "peace-of-mind" */ '@/views/public/PeaceOfMind.vue'),
      meta: {
        fluidContainer: true,
        public: true
      }
    },
    {
      path: '/reviews',
      name: 'reviews',
      component: () => import(/* webpackChunkName: "reviews" */ '@/views/public/Reviews.vue'),
      meta: {
        fluidContainer: true,
        public: true
      }
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import(/* webpackChunkName: "contact" */ '@/views/public/Contact.vue'),
      meta: {
        fluidContainer: true,
        public: true
      }
    },
    {
      path: '/partner-with-openbay',
      name: 'partner-with-openbay',
      component: () => import(/* webpackChunkName: "partner-with-openbay" */ '@/views/public/PartnerWithOpenbay.vue'),
      meta: {
        fluidContainer: true,
        public: true
      }
    },
    {
      path: '/openbay-plus-faq',
      name: 'openbay-plus-faq',
      component: () => import(/* webpackChunkName: "openbay-plus-faq" */ '@/views/public/OpenbayPlusFaq.vue'),
      meta: {
        fluidContainer: true,
        public: true
      }
    },
    {
      path: '/automotive-service-professionals/apply',
      name: 'automotive-service-professionals-apply',
      component: () => import(/* webpackChunkName: "automotive-service-professionals-apply" */ '@/views/AspApply.vue'),
      meta: {
        fluidContainer: true,
        public: true
      }
    },
    {
      path: '/openbayplus',
      name: 'openbayplus',
      component: () => import(/* webpackChunkName: "openbayplus" */ '@/views/OpenbayPlus.vue'),
      meta: {
        fluidContainer: true,
        public: true
      }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import(/* webpackChunkName: "dashboard" */ '@/components/Dashboard.vue'),
      meta: {
        transitionName: 'slide-right'
      }
    },
    {
      path: '/sign-up',
      name: 'sign-up',
      component: () => import(/* webpackChunkName: "sign-up" */ '@/components/Auth/SignUpPage.vue'),
      beforeEnter: (to, from, next) => {
        if (isLoggedIn()) next('/dashboard');
        else next();
      },
      meta: {
        transitionName: 'slide',
        fluidContainer: true,
        public: true
      }
    },
    {
      path: '/activate-account',
      name: 'activate-account',
      component: () => import(/* webpackChunkName: "activate-account" */ '@/views/StandardAccountActivation.vue'),
      beforeEnter: (to, from, next) => {
        if (isLoggedIn() && accountIsConfirmed()) next('/dashboard');
        else next();
      },
      meta: {
        transitionName: 'slide',
        fluidContainer: true,
        public: true
      }
    },
    {
      path: '/sign-up/success',
      name: 'sign-up-success',
      component: () => import(/* webpackChunkName: "sign-up-success" */ '@/views/RegistrationSuccess.vue'),
      meta: {
        transitionName: 'slide',
        fluidContainer: true,
        public: false
      },
      beforeEnter: (_to, _from, next) => {
        if (!store.getters['user/hasActiveSubscription']) {
          next();
        } else {
          next('/dashboard');
        }
      }
    },
    {
      path: '/auto-essentials',
      name: 'auto-essentials',
      component: () => import(/* webpackChunkName: "auto-essentials" */ '@/views/AutoEssentials.vue'),
      meta: {
        public: false
      },
      beforeEnter: (to, from, next) => {
        if (userHasOffers()) {
          next();
        } else {
          next('/dashboard');
        }
      }
    },
    {
      path: '/subscriptions',
      name: 'subscriptions-page',
      component: () => import(/* webpackChunkName: "subscriptions-page" */ '@/views/Subscriptions.vue'),
      meta: {
        public: false
      },
      beforeEnter: (to, from, next) => {
        if (!store.getters['user/hasActiveSubscription']) {
          next();
        } else {
          next('/dashboard');
        }
      }
    },
    {
      path: '/sign-in',
      name: 'sign-in',
      component: () => import(/* webpackChunkName: "sign-in" */ '@/components/Auth/SignInPage.vue'),
      props: {
        initialForm: 'sign-in-form'
      },
      beforeEnter: (to, from, next) => {
        if (isLoggedIn()) next('/dashboard');
        else next();
      },
      meta: {
        transitionName: 'slide',
        fluidContainer: true,
        public: true
      }
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import(/* webpackChunkName: "sign-in" */ '@/components/Auth/SignInPage.vue'),
      props: {
        forgotPassword: true
      },
      beforeEnter: (to, from, next) => {
        if (isLoggedIn()) next('/dashboard');
        else next();
      },
      meta: {
        transitionName: 'slide',
        fluidContainer: true,
        public: true
      }
    },
    {
      path: '/reset-password/:token',
      name: 'reset-password',
      component: () => import(/* webpackChunkName: "sign-up" */ '@/components/Auth/PasswordReset.vue'),
      beforeEnter: verifyPasswordResetToken,
      meta: {
        transitionName: 'slide',
        fluidContainer: true,
        public: true
      }
    },
    {
      path: '/rewards',
      name: 'rewards',
      component: () => import(/* webpackChunkName: "rewards" */ '@/views/Rewards.vue'),
      meta: {
        transitionName: 'slide'
      }
    },
    ...profileRoutes,
    {
      path: '/messages',
      name: 'messages',
      component: () => import(/* webpackChunkName: "messages" */ '@/components/Messages/MessagesPage.vue'),
      meta: {
        title: 'Messages',
        transitionName: 'slide'
      }
    },
    {
      path: '/invite/:promoSlug',
      name: 'invite-page',
      component: () => import(/* webpackChunkName: "promo-invite" */ './views/PromoInvite.vue'),
      beforeEnter: (_to, _from, next) => {
        if (isLoggedIn()) next('/dashboard');
        else next();
      },
      meta: {
        fluidContainer: true,
        hideNavLinks: true,
        public: true
      }
    },
    ...onrampRoutes,
    {
      path: '/service-request/:serviceRequestId/offer/:offerId/reschedule',
      name: 'reschedule',
      component: () =>
        import(/* webpackChunkName: "reschedule-selector" */ '@/components/ServiceRequests/RescheduleSelector.vue'),
      meta: {
        title: 'Reschedule your appointment'
      }
    },
    ...offerRoutes,
    ...repairCostRoutes,
    ...autoRepairRoutes,
    ...maintenanceRoutes,
    ...subscriptionRoutes,
    ...appointmentRoutes,
    {
      path: '/429',
      name: '429',
      component: () => import(/* webpackChunkName: "429" */ '@/components/TooManyRequests.vue'),
      meta: {
        public: true,
        hideNavLinks: true
      }
    },
    {
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "404" */ '@/components/NotFound.vue'),
      meta: {
        public: true
      }
    }
  ]
});

router.beforeEach(waitForStorageToBeReady);
router.beforeEach(storeGivenVin);
router.beforeEach(requireLoginForNonPublicRoutes);
router.beforeEach(requireSubscriberPasswordForNonPublicRoutes);
router.beforeEach(checkPaywallForLoggedInUsers);

export default router;
